.Header-container {
    padding: 8px 60px;
    display: flex;
    flex-direction: row;
    animation: fade-down 2s;
}

@media only screen and (max-width: 375px) {
    .Header-container{
        padding: 8px 12px;
    }
}

.Header-social-links-nav {
    display: flex;
    flex-direction: row;
    gap: 4px
}

.Header-social-logo {
    width: 30px;
    height: 30px;
    opacity: .80;
}

@keyframes fade-down {
    0% {
        opacity: 0;
        transform: translateY(-20px) scale(0.9);
    }
    100% {
        opacity: 1;
        transform: translateY(0px) scale(1);
    }
}