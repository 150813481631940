p {
  margin: 16px 0 0;
  font-size: 22px;
}

.App {
  background: #3A1C71; /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #FFAF7B, #D76D77, #3A1C71); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #FFAF7B, #D76D77, #3A1C71); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  text-align: center;
}

.coming-soon-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fade-up 2s;
}

.sleepy-animation-container {
  animation: fade-down 2s;
}

.coming-soon-page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  max-width: 700px;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-container {
  background: #3A1C71; /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #FFAF7B, #D76D77, #3A1C71); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #FFAF7B, #D76D77, #3A1C71); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  min-height: calc(100vh - 49.5px);
  padding: 0 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.seva-form.formkit-form {
  max-width: 100%;
  background: none;
}

.formkit-form[data-uid="76265e0ace"] [data-style="clean"] {
  max-width: 440px;
}

button {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  border:1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
}

.form-disclaimer {
  font-size: small;
  width: 80%;
}

@keyframes fade-up {
  0% {
    opacity: 0;
    transform: translateY(30px) scale(0.9);
  }
  100% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}

@keyframes fade-down {
  0% {
    opacity: 0;
    transform: translateY(-30px) scale(0.9);
  }
  100% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}

@media only screen and (max-width: 992px) {
  .App-container {
    padding: 14px 140px;
    gap: 24px;
  }
}

@media only screen and (max-width: 768px) {
  .App-container {
    padding: 14px 80px;
  }

  p {
    font-size: 18px;
  }

  .form-disclaimer {
    max-width: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .App-container {
    padding: 24px 20px;
    gap: 24px;
    min-height: calc(100vh - 96px);
  }

  .sleepy-gif {
    width: 300px;
    height: 175px;
  }

  p {
    font-size: 16px;
  }
}